import React from "react";

import { Link, animateScroll as scroll } from "react-scroll";
import "./NavBar.css";

export default function NavBar({ mobile }) {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <nav className="nav" id="navbar">
      <div className="nav-content">
        <img
          src="/img/logo100.png"
          className="nav-logo"
          alt="Logo"
          onClick={scrollToTop}
        />
        {!mobile && (
          <ul className="nav-items">
            <li className="nav-item">
              <Link
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                about
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                work
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="section3"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                contact + links
              </Link>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}
