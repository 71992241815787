//styles
import "./Home.css";

import { useState } from "react";

export default function Home({ id }) {
  const [src, setSrc] = useState("/img/logo_full_flipped.png");

  return (
    <div className="section-content">
      <div id={id} className="logo">
        <img
          onMouseEnter={() => setSrc("/img/logo_full.png")}
          onMouseLeave={() => setSrc("/img/logo_full_flipped.png")}
          src={src}
          alt="logo"
        ></img>
      </div>
      <div className="home-content">
        <p className="home-text">
          I'm Kevin, an applications developer currently based in Charleston,
          South Carolina. I've been working in IT professionally since I
          graduated Virginia Tech in 2015. I have epxperience in Java with
          Spring Framework, Angular, React, and Node. I have jumped around from
          functional to technical IT project roles covering nearly every
          position in a tradtional software development life cycle including PM,
          Business Analyst, QA, and both front and backend development. Having
          held various project roles gives me a unique persepctive in fully
          utilizing cross functional devlopment strategies.
        </p>
        <img alt="headshot" src="/img/headshot200.jpg" />
      </div>
    </div>
  );
}
