import React from 'react';
import ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';

const container = document.getElementById('app');
const root = ReactDOMClient.createRoot(container)

root.render(
    <App />
);


//OLD
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );


