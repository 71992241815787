//imports
import { useState } from "react";

//styles
import "./Life.css";

import BracIcon from "../../assets/bracari.png";
import CgiIcon from "../../assets/cgi.png";
import cabelasIcon from "../../assets/cabelas.png";
import fnIcon from "../../assets/fn.jpeg";
import campusCookies from "../../assets/campusCookies.png";

export default function Life({ id }) {
  const [showBrac, setShowBrac] = useState(false);
  const [showCgi, setShowCgi] = useState(false);
  const [showFn, setShowFn] = useState(false);
  const [showCabelas, setShowCabelas] = useState(false);
  const [showCampusCookies, setShowCampusCookies] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    switch (e.target.id) {
      case "brac":
        setShowBrac(!showBrac);
        break;
      case "cgi":
        setShowCgi(!showCgi);
        break;
      case "fn":
        setShowFn(!showFn);
        break;
      case "cabelas":
        setShowCabelas(!showCabelas);
        break;
      case "campusCookies":
        setShowCampusCookies(!showCampusCookies);
        break;
      default:
        break;
    }
  };
  return (
    <div className="section-content">
      <div id={id}>
        <h2>Work Experience</h2>
        <p>Click the logos to toggle work experience</p>
        <div className="image-div">
          <img
            src={BracIcon}
            onClick={handleClick}
            id="brac"
            alt="Bracri Icon"
          />
        </div>
        {showBrac && (
          <div>
            <h4>Bracari</h4>
            <p>05/2021 - Present</p>

            <p>
              Bracari is a small business government IT contractor based in
              Charleston, SC. As a Business Analyst I worked on the Veteran
              Affairs (VA) Electronic Health Record Modernization (EHRM) project
              working with technical resources to design integration points
              between Cerner-Oracle EHR and 3rd party health software. As a
              Developer I worked with 6 other developers on a proprietary React
              and Java + Spring application to manage benefit exams for
              Veteran's that also integrated with Cerner-Oracle's EHR.
            </p>
            <p>Full Stack Developer</p>
            <ul>
              <li>
                Managed and worked alongside front end team developing reusable
                front-end components to provide 90 varyingly complex
                questionnaire forms.
              </li>
              <li>
                Developed services to transmit HL7 messages to VA systems to
                schedule, conduct, and manage exams for Veteran's.
              </li>
              <li>
                Developed design standards for front end applications. Rolled
                styled components into child objects to provide consistent feel
                and function to application
              </li>
            </ul>
            <p>Business Analyst</p>
            <ul>
              <li>
                Host design sessions between technical parties and clinical
                users of medical software. Ensure that technical aspects of the
                system are described in ways that everyday users fully
                understand.
              </li>
              <li>
                Document requirements, workflows, use cases, technical design
                patterns, security standards, technical messaging formatting,
                and technical connectivity details.
              </li>
              <li>
                Continuously improve templates and review processes with
                multiple team representatives to ensure a complete and useable
                technical design is presented to stakeholders while maintaining
                an efficient process.
              </li>
            </ul>
          </div>
        )}
        <div className="image-div">
          <img src={CgiIcon} id="cgi" onClick={handleClick} alt="CGI Icon" />
        </div>
        {showCgi && (
          <div>
            <h4>CGI Federal</h4>
            <p>01/2021 - 05/2021</p>

            <p>
              CGI Federal is a large government IT consulting agency. With CGI I
              worked on the Department of State contract in efforts to upgrade
              Momentum ERP. This involved updating design documents; writing and
              executing test plans; creating, running, and testing batch
              scripts; and working with government accountants to verify complex
              monetary transactions.
            </p>
            <p>Business Analyst</p>
            <ul>
              <li>
                Maintain software design documents for custom software through
                version updates, update software designs for additional
                functionality.
              </li>
              <li>
                Design and execute software test plans to determine if the
                system is performing properly and efficiently.
              </li>
              <li>
                Present design changes, test results, and updates directly to
                the client as needed to keep them informed with progress and
                changes to the functionality of the software.
              </li>
              <li>
                Configure and execute batch cycles and troubleshoot any
                outstanding issues that may arise with new software versions.
              </li>
            </ul>
          </div>
        )}
        <div className="image-div">
          <img src={fnIcon} id="fn" onClick={handleClick} alt="FN Icon" />
        </div>
        {showFn && (
          <div>
            <h4>FN America</h4>
            <p>05/2018 - 12/2019</p>
            <p>
              FN America, based in Columbia, SC, is a military firearms
              manufaturer. In the small IT department I was responsible for
              multiple systems including the enterprise sharepoint
              administration and custom built APIs primarily using node.js.
            </p>
            <p>Systems Developer</p>
            <ul>
              <li>
                Developed API connection and data parsing using Node.JS with a
                direct connection to a Microsoft SQL server to store data for
                reporting on customer service KPIs
              </li>
              <li>
                Developed API interface to 3rd party payroll software using
                Node.JS and Microsoft Powershell scripts to automate on
                boarding. Automatically creating Active Directory accounts,
                Office 365 accounts, and creating a service ticket notifying
                support of necessary hardware.
              </li>
              <li>
                Administrate and develop SharePoint intranet sites. Completed
                custom workflows using Nintex forms and workflows.
              </li>
            </ul>
          </div>
        )}
        <div className="image-div">
          <img
            src={cabelasIcon}
            id="cabelas"
            onClick={handleClick}
            alt="Cabelas Icon"
          />
        </div>
        {showCabelas && (
          <div>
            <h4>Cabela's</h4>
            <p>06/2015 - 05/2018</p>
            <p>
              Cabela's was the nations second largest outdoor retailer based in
              Sidney, Nebraska. I started on the ".com" team working on
              implementing features such as inventory tracker and tracing and
              fixing defects on the eCommerce website www.cabelas.com. I moved
              to internal application development and with 5 other developers
              built and maintained an enterprise application to track the
              purchase, transfer, and sale of firearms called the Electronic
              Bound Book, eventually becoming the technical SME.
            </p>
            <p>Full Stack Developer</p>
            <ul>
              <li>
                Developed proprietary Alcohol, Tobacco, and Firearms (ATF)
                tracking and compliance web application using Java, Angular JS,
                HTML and CSS.
              </li>
              <li>
                Work directly with business partners to find improvements in
                software. Visit retail and distribution center locations to
                analyze use of software for continuous improvements.
              </li>
              <li>
                Developed mobile interface for ATF Compliance web application,
                allowing employees to complete daily tasks away from hard wired
                devices.
              </li>
              <li>
                Developed in-store inventory check feature for cabelas.com
                website using Java, JavaScript and HTML allowing the customer to
                view inventory of local retail locations online.
              </li>
            </ul>
          </div>
        )}
        <div className="image-div">
          <img
            src={campusCookies}
            id="campusCookies"
            onClick={handleClick}
            alt="Campus Cookies Icon"
          />
        </div>
        {showCampusCookies && (
          <div>
            <h4>Campus Cookies</h4>
            <p>08/2012 - 05/2015</p>
            <p>
              MY college job at Campus Cookies where I became the General
              Manager for the Blackburg location after working as an employee
              for a few short weeks. A late cookie delivery business run
              entirely by college students and one of 6 locations owned by a
              recent college graduate. While I didn't gain any direct IT
              experience here but I've found throughout my career that I learned
              more about people and time management trying to effectively manage
              college students around class schedules while being a college
              student myself.
            </p>
            <p>General Manager</p>
            <ul>
              <li>
                Reviewed resumes, conducted interviews, and made hiring
                decisions based on varrying seasonal demands.
              </li>
              <li>Handled invetory forecasting, ordering, and recieving.</li>
              <li>
                Created schedules, reviewed performance, organzied team outings
                for existing employees, and worked along side existing team in
                day to day cookie baking/delivering activities.
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
