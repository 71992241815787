//styles
import "./Contact.css";

export default function Contact({ id, mobile }) {
  return (
    <div className="section-content">
      {mobile && (
        <div>
          <h4>Mobile resolution under construction.</h4>
          <p>please view on desktop browser or visit my pages below</p>
        </div>
      )}
      <div id={id}>
        {!mobile && <h2>How to get in touch</h2>}
        <p>heuple.kevin@gmail.com</p>
        <div>
          <a target="_new" href="https://www.linkedin.com/in/kevin-heuple/">
            LinkedIn
          </a>
        </div>
        <div>
          <a target="_new" href="https://github.com/heuplek">
            Git Hub
          </a>
        </div>
        <div>
          <a
            target="_new"
            href="https://www.credly.com/badges/c7cdec7f-50be-4b93-838d-080668d806a8?source=linked_in_profile"
          >
            PMI PMP Certification
          </a>
        </div>
      </div>
    </div>
  );
}
