import React from "react";
import "./App.css";
import Navbar from "./components/NavBar";
import Home from "./pages/home/Home";
import Life from "./pages/life/Life";
import Contact from "./pages/contact/Contact";
import useWindowDimensions from "./hooks/windowDimensions";

export default function App() {
  const window = useWindowDimensions();
  return window.width < 800 ? (
    <div className="App">
      <Navbar mobile={true} />
      <Contact id="section3" mobile={true} />
    </div>
  ) : (
    <div className="App">
      <Navbar mobile={false} />
      <Home id="section1" />
      <Life id="section2" />
      <Contact id="section3" mobile={false} />
    </div>
  );
}
